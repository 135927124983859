<template>
  <div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-12 2xl:col-span-12">
        <!-- BEGIN: Change Password -->
        <div class="intro-y box lg:mt-5">
          <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
            <h2 class="font-medium text-base">{{ i18n('changePassword.title') }}</h2>
          </div>
          <div class="flex justify-center">

            <div class="grid-cols-8">
              <div class="p-5 col-span-8">
                <div class="mt-3">
                  <label for="change-password-form-1" class="form-label">
                    {{ i18n('changePassword.oldPassword') }}
                  </label>
                  <input id="change-password-form-1" type="password" class="form-control dark:bg-dark-9"
                    :placeholder="i18n('changePassword.oldPassword')" v-model="form.oldPassword" />
                </div>
                <div class="mt-3">
                  <label for="change-password-form-2" class="form-label">
                    {{ i18n('changePassword.newPassword') }}
                  </label>
                  <input id="change-password-form-2" type="password" class="form-control dark:bg-dark-9"
                    :placeholder="i18n('changePassword.newPassword')" v-model="form.newPassword" />
                </div>
                <div class="mt-3">
                  <label for="change-password-form-3" class="form-label">
                    {{ i18n('changePassword.confirmPassword') }}
                  </label>
                  <input id="change-password-form-3" type="password" class="form-control dark:bg-dark-9"
                    :placeholder="i18n('changePassword.confirmPassword')" v-model="form.confirmPassword" />
                </div>
                <div class="mt-3">
                  <button type="button" class="btn bg-theme-action-1 text-white mt-4" :disabled="loading"
                    @click="doSubmit()">
                    <span v-if="!loading">{{ i18n('changePassword.changePassword') }}</span>
                    <span v-if="loading">{{ i18n('common.loading') }}</span>
                    <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px;" v-if="loading" />
                  </button>
                  <button type="button" class="btn bg-theme-action-2 text-black mt-4 ml-4 mr-4" :disabled="loading"
                    @click="doCancel()">
                    {{ i18n('common.cancel') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Change Password -->
      </div>
    </div>
    <ErrorNotification :message="errorMessage" />
    <SuccessNotification message="changePassword.success" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
// import { useStore } from '@/store'
import { mapGetters, mapActions } from 'vuex'
import ErrorNotification from '@/components/notifications/error-notification.vue'
import SuccessNotification from '@/components/notifications/success-notification.vue'
import Toastify from 'toastify-js'
import Message from '@/shared/message/toastify'

export default defineComponent({
  components: {
    ErrorNotification,
    SuccessNotification
  },
  setup() {
    // const store = useStore()
    // const isRTL = computed(() => store.state.layout.isRTL)

    const form = ref({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    })
    const errorMessage = ref('')
    const errorNotificationToggle = () => {
      Toastify({
        node: cash('#error-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    const successNotificationToggle = () => {
      Toastify({
        node: cash('#success-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: -1,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    return {
      // isRTL,
      form,
      errorMessage,
      errorNotificationToggle,
      successNotificationToggle
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.changePassword')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.changePassword')
    }
  },
  computed: {
    ...mapGetters({
      loading: 'auth/loadingChangeMyPassword',
      isRTL: 'layout/isRTL'
    })
  },
  methods: {
    ...mapActions({
      doChangeMyPassword: 'auth/doChangeMyPassword'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    doCancel() {
      this.$router.push('/profile')
    },
    async doSubmit() {
      if (this.isFormValid()) {
        await this.doChangeMyPassword({
          oldPassword: this.form.oldPassword,
          newPassword: this.form.newPassword
        })
      } else {
        const ERROR = this.i18n(this.errorMessage)
        Message.error(ERROR)
      }
    },
    isFormValid() {
      for (var key in this.form) {
        var value = this.form[key]
        if (value.length == 0) {
          this.errorMessage = 'changePassword.errors.emptyFields'
          console.log(this.errorMessage)
          return false
        }
      }
      if (this.form.newPassword != this.form.confirmPassword) {
        this.errorMessage = 'changePassword.errors.passwordsDontMatch'
        console.log(this.errorMessage)
        return false
      }
      return true
    }
  }
})
</script>
